<template>
  <div>
    <el-dialog
      :title="title"
      :visible.sync="showDialog"
      width="600px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="onCloseDialog"
    >
      <span class="content-box">
        <el-form label-width="auto" :inline="false" size="mini">
          <el-row :gutter="rowSpan">
            <el-col :span="colSpan">
              <el-form-item label="分类名称" required>
                <el-input v-model="submitData.Name"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="colSpan">
              <el-form-item label="上级分类">
                <el-select v-model="submitData.ParentId" filterable @change="">
                  <el-option
                    v-for="item in categoryList"
                    :key="item.CategoryId"
                    :label="item.Name"
                    :value="item.CategoryId"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="colSpan">
              <el-form-item label="分类排序">
                <el-input v-model="submitData.Sort"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="colSpan">
              <el-form-item label="分类图片">
                <div class="picture-box">
                  <el-button type="primary" @click="onShowPicture"
                    >选择图片</el-button
                  >
                  <el-image
                    v-show="submitData.Image"
                    :src="submitData.Image"
                    style="height: 100px; width: 100px"
                    fit="scale-down"
                  ></el-image>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </span>
      <span slot="footer">
        <el-button @click="onCloseDialog">取 消</el-button>
        <el-button type="primary" @click="saveCategory">确 定</el-button>
      </span>
    </el-dialog>

    <DFGalleryPictrue
      ref="DFGalleryPictrue"
      @onComfirm="onSelectPicture"
    ></DFGalleryPictrue>
  </div>
</template>

<script>
import Shop from "@/api/shop.js";

export default {
  props: {
    dataList: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      title: "",
      rowSpan: 21,
      colSpan: 12,
      submitData: {
        CategoryId: 0,
        Name: "",
        ParentId: 0,
        Sort: 100,
        Image: "",
      },
      categoryList: [],
      showDialog: false,
    };
  },

  watch: {
    dataList: {
      handler(newVal) {
        let arr = JSON.parse(JSON.stringify(newVal));
        arr = arr.filter((item) => item.Level == 1);
        arr.unshift({
          Level: 0,
          CategoryId: 0,
          ParentId: 0,
          Name: "顶级分类",
        });
        this.categoryList = arr;
      },
      immediate: true,
      deep: true,
    },
  },

  mounted() {},

  methods: {
    // 保存分类
    async saveCategory() {
      let { submitData } = this;
      if (!submitData.Name) {
        return this.$messgae.error("请输入分类名称");
      }
      submitData = {
        Name: submitData.Name,
        CategoryId: submitData.CategoryId,
        ParentId: submitData.ParentId,
        Sort: submitData.Sort,
        Image: submitData.Image,
      }
      try {
        let { errcode } = await Shop.saveCategory(submitData);
        if (errcode == 0) {
          this.$message.success("保存成功");
          this.$emit("success");
          this.resetData();
          this.onCloseDialog();
        }
      } catch (err) {
        console.log(err);
      }
    },

    // 选中图片返回事件
    onSelectPicture(event) {
      this.submitData.Image = event[0].MediaUrl;
    },

    // 重置数据
    resetData() {
      this.submitData = {
        CategoryId: 0,
        Name: "",
        ParentId: 0,
        Sort: 100,
        Image: "",
      };
    },

    // 打开图片库对话框
    onShowPicture() {
      this.$refs.DFGalleryPictrue.onShowDialog();
    },

    // 打开对话框
    onShowDialog(event) {
      this.title = event == "" ? "添加商品分类" : "修改商品分类";
      this.submitData = JSON.parse(JSON.stringify(event || this.submitData));
      this.showDialog = true;
    },

    // 关闭对话框
    onCloseDialog() {
      this.showDialog = false;
    },
  },
};
</script>

<style lang="less" scoped>
.content-box {
  .picture-box{
    .flex-col;
    align-items: start;
  }
}
</style>
