<template>
  <div class="category">
    <div class="df-row">
      <div class="df-col">
        <el-button type="primary" size="mini" @click="onAddCategory('')">新增</el-button>
      </div>
    </div>

    <el-table
      :data="tableData"
      :max-height="tableMaxHeight"
      size="default"
      border
      stripe
    >
      <el-table-column prop="CategoryId" label="分类ID"> </el-table-column>
      <el-table-column prop="" label="分类名称">
        <template slot-scope="scope">{{ scope.row.Prefix + scope.row.Name }}</template>
      </el-table-column>
      <el-table-column prop="Sort" label="分类排序"> </el-table-column>
      <el-table-column prop="" label="添加时间">
        <template slot-scope="scope">{{ !scope.row.CreateTime ? '' : util.dateFormat('YYYY-mm-dd HH:MM:SS', new Date(scope.row.CreateTime * 1000)) }}</template>
      </el-table-column>
      <el-table-column prop="" label="操作">
        <template slot-scope="scope">
          <el-button type="primary" size="mini" @click="onAddCategory(scope.row)">编辑</el-button>
          <el-button type="danger" size="mini" @click="deleteCategory(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 添加商品分类对话框 -->
    <AddCategoryDialog ref="AddCategoryDialog" :dataList="tableData" @success="getCategoryList"></AddCategoryDialog>
  </div>
</template>

<script>
import Shop from "@/api/shop.js"

import AddCategoryDialog from "./components/add-category-dialog.vue";

export default {
  components: { AddCategoryDialog },

  data() {
    return {
      tableMaxHeight: "",
      tableData: [],
    };
  },

  created() {
    this.getResize();
  },

  async mounted() {
    window.addEventListener("resize", this.getResize);

    this.getCategoryList()
  },

  destroyed() {
    window.removeEventListener("resize", this.getResize);
  },

  methods: {
    // 获取浏览器窗口大小
    getResize() {
      let { clientHeight } = this.util.getDocumentElementInfo();
      this.tableMaxHeight = clientHeight - (100 + 40 + 68 );
    },

    // 获取分类列表
    async getCategoryList(){
      try {
        let { data } = await Shop.getCategoryList()
        this.tableData = data
      } catch (err) {
        console.log(err);
      }
    },

    // 删除分类
    deleteCategory(event){
      this.$confirm('此操作将永久删除该分类, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        try {
          let { errcode } = await Shop.deleteCategory({
            category_id: event.CategoryId
          })
          if(errcode == 0){
            this.$message.success('删除成功')
            this.getCategoryList()
          }
        } catch (err) {
          console.log(err);
        }
      })
    },

    // 打开添加商品分类对话框
    onAddCategory(event = ''){
        this.$refs.AddCategoryDialog.onShowDialog(event)
    }
  },
};
</script>

<style lang="less" scoped>
.category {
  padding-top: 20px;
  .df-row {
  }
}
</style>
